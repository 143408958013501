var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-flex",
    { staticClass: "rtb-game-users-page", attrs: { "pa-0": "" } },
    [
      _vm._v(" TEST "),
      _c("v-data-table", {
        attrs: {
          headers: _vm.headers,
          items: _vm.desserts,
          "single-select": _vm.singleSelect,
          "item-key": "name",
          "show-select": "",
        },
        scopedSlots: _vm._u([
          {
            key: "top",
            fn: function () {
              return [
                _c("v-switch", {
                  staticClass: "pa-3",
                  attrs: { label: "Single select" },
                  model: {
                    value: _vm.singleSelect,
                    callback: function ($$v) {
                      _vm.singleSelect = $$v
                    },
                    expression: "singleSelect",
                  },
                }),
              ]
            },
            proxy: true,
          },
        ]),
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
      _c(
        "v-btn",
        {
          on: {
            click: function ($event) {
              return _vm.onCreateTeam()
            },
          },
        },
        [_vm._v(" Create Team")]
      ),
      _vm._l(_vm.allTeams, function (item, index) {
        return _c(
          "v-flex",
          { key: index, attrs: { "pa-1": "", "no-action": "" } },
          [
            _c(
              "draggable",
              {
                staticClass: "team-name",
                on: {
                  change: function ($event) {
                    return _vm.changeTeam($event, item.id)
                  },
                },
              },
              [
                _vm._v(" " + _vm._s(item.name) + " "),
                _c("span", { staticStyle: { float: "right" } }, [
                  _vm._v(_vm._s(item.totalScore) + " pts "),
                ]),
                _c(
                  "draggable",
                  {
                    staticClass: "player-card",
                    attrs: {
                      list: item.items,
                      options: { group: "people", animation: 150 },
                    },
                    on: {
                      change: function ($event) {
                        return _vm.changeTeam($event, item.id)
                      },
                    },
                  },
                  _vm._l(item.items, function (subItem) {
                    return _c(
                      "v-card",
                      {
                        key: subItem.id,
                        staticStyle: { float: "left", padding: "4px" },
                        attrs: { "pa-5": "", "min-width": "190" },
                        on: {
                          dblclick: function ($event) {
                            return _vm.selectForSocial(subItem)
                          },
                          click: [
                            function ($event) {
                              if (!$event.shiftKey) {
                                return null
                              }
                              return _vm.editPlayer(subItem)
                            },
                            function ($event) {
                              if (!$event.altKey) {
                                return null
                              }
                              return _vm.editPlayer(subItem)
                            },
                          ],
                        },
                      },
                      [
                        _c(
                          "v-flex",
                          { attrs: { "d-flex": "" } },
                          [
                            _c(
                              "v-layout",
                              [
                                _c(
                                  "v-flex",
                                  { attrs: { "d-flex": "" } },
                                  [
                                    _c(
                                      "v-layout",
                                      { attrs: { column: "" } },
                                      [
                                        _c("v-flex", [
                                          _vm._v(
                                            " " +
                                              _vm._s(subItem.firstname) +
                                              " " +
                                              _vm._s(subItem.lastname) +
                                              " "
                                          ),
                                        ]),
                                        !!subItem.email
                                          ? _c(
                                              "v-flex",
                                              {
                                                staticClass:
                                                  "game-users-user-email",
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.copyToClipboard(
                                                      subItem.email
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(subItem.email) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                        !!subItem.totalScore
                                          ? _c("v-flex", [
                                              _vm._v(
                                                " Score: " +
                                                  _vm._s(subItem.totalScore) +
                                                  " "
                                              ),
                                            ])
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        )
      }),
      _c(
        "v-dialog",
        {
          key: _vm.dialogKey,
          attrs: { "max-width": "400px" },
          model: {
            value: _vm.editPlayerDialog,
            callback: function ($$v) {
              _vm.editPlayerDialog = $$v
            },
            expression: "editPlayerDialog",
          },
        },
        [
          _c(
            "v-layout",
            { attrs: { "justify-center": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-toolbar",
                    { attrs: { color: "indigo", dark: "", height: "50px" } },
                    [
                      _c("v-toolbar-title", [
                        _vm._v("Edit " + _vm._s(_vm.player.firstname)),
                      ]),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "", "grid-list-md": "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                [
                                  _c(
                                    "form",
                                    {
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                          return _vm.onSignin.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              name: "firstname",
                                              label: "First Name",
                                            },
                                            model: {
                                              value: _vm.player.firstname,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.player,
                                                  "firstname",
                                                  $$v
                                                )
                                              },
                                              expression: "player.firstname",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              name: "username",
                                              label: "UserName",
                                            },
                                            model: {
                                              value: _vm.player.username,
                                              callback: function ($$v) {
                                                _vm.$set(
                                                  _vm.player,
                                                  "username",
                                                  $$v
                                                )
                                              },
                                              expression: "player.username",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      !!_vm.user.super
                                        ? _c(
                                            "v-card-text",
                                            [
                                              _c("v-select", {
                                                attrs: {
                                                  items: _vm.accessLevels,
                                                  label: "ACCESS",
                                                  "item-text": "text",
                                                  "item-value": "code",
                                                },
                                                model: {
                                                  value: _vm.accessLevel,
                                                  callback: function ($$v) {
                                                    _vm.accessLevel = $$v
                                                  },
                                                  expression: "accessLevel",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          on: {
                                            click: function ($event) {
                                              _vm.editPlayerDialog = false
                                            },
                                          },
                                        },
                                        [_vm._v(" Cancel ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "primary",
                                          on: {
                                            click: function ($event) {
                                              return _vm.savePlayer()
                                            },
                                          },
                                        },
                                        [_vm._v(" Save ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { icon: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.deletePlayer()
                                            },
                                          },
                                        },
                                        [_c("v-icon", [_vm._v("delete")])],
                                        1
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          attrs: { icon: "" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.bouncePlayer()
                                            },
                                          },
                                        },
                                        [_c("v-icon", [_vm._v("call_made")])],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "v-dialog",
        {
          attrs: { "max-width": "400px" },
          model: {
            value: _vm.editTeam,
            callback: function ($$v) {
              _vm.editTeam = $$v
            },
            expression: "editTeam",
          },
        },
        [
          _c(
            "v-layout",
            { attrs: { "justify-center": "" } },
            [
              _c(
                "v-flex",
                { attrs: { xs12: "" } },
                [
                  _c(
                    "v-toolbar",
                    {
                      attrs: {
                        color: _vm.$theme.get("--primary-accent-color"),
                        dark: "",
                        height: "50px",
                      },
                    },
                    [
                      _c("v-toolbar-title", [_vm._v("Create Team ")]),
                      _c("v-spacer"),
                    ],
                    1
                  ),
                  _c(
                    "v-card",
                    [
                      _c(
                        "v-container",
                        { attrs: { fluid: "", "grid-list-md": "" } },
                        [
                          _c(
                            "v-layout",
                            { attrs: { row: "", wrap: "" } },
                            [
                              _c(
                                "v-flex",
                                [
                                  _c(
                                    "form",
                                    {
                                      on: {
                                        submit: function ($event) {
                                          $event.preventDefault()
                                          return _vm.onSignin.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        "v-card-text",
                                        [
                                          _c("v-text-field", {
                                            attrs: {
                                              name: "firstname",
                                              label: "Team Name",
                                            },
                                            model: {
                                              value: _vm.team.name,
                                              callback: function ($$v) {
                                                _vm.$set(_vm.team, "name", $$v)
                                              },
                                              expression: "team.name",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "v-card-actions",
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "pick-team-btn-cancel",
                                          on: {
                                            click: function ($event) {
                                              _vm.editTeam = false
                                            },
                                          },
                                        },
                                        [_vm._v(" Cancel ")]
                                      ),
                                      _c(
                                        "v-btn",
                                        {
                                          staticClass: "pick-team-btn-save",
                                          on: { click: _vm.saveTeam },
                                        },
                                        [_vm._v(" Save ")]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }