<template>
  <v-flex pa-0 class="rtb-game-users-page">
    TEST
    <v-data-table
      v-model="selected"
      :headers="headers"
      :items="desserts"
      :single-select="singleSelect"
      item-key="name"
      show-select
    >
      <template v-slot:top>
        <v-switch
          v-model="singleSelect"
          label="Single select"
          class="pa-3"
        ></v-switch>
      </template>
    </v-data-table>
    <v-btn @click="onCreateTeam()"> Create Team</v-btn>
    <v-flex v-for="(item, index) in allTeams" :key="index" pa-1 no-action>
      <draggable class="team-name" @change="changeTeam($event, item.id)">
        {{ item.name }}
        <span style="float: right">{{ item.totalScore }} pts </span>
        <draggable
          :list="item.items"
          :options="{ group: 'people', animation: 150 }"
          class="player-card"
          @change="changeTeam($event, item.id)"
        >
          <v-card
            v-for="subItem in item.items"
            :key="subItem.id"
            pa-5
            style="float: left; padding: 4px"
            min-width="190"
            @dblclick="selectForSocial(subItem)"
            @click.shift="editPlayer(subItem)"
            @click.alt="editPlayer(subItem)"
          >
            <v-flex d-flex>
              <v-layout>
                <v-flex d-flex>
                  <v-layout column>
                    <v-flex>
                      {{ subItem.firstname }} {{ subItem.lastname }}
                    </v-flex>
                    <v-flex
                      v-if="!!subItem.email"
                      @click="copyToClipboard(subItem.email)"
                      class="game-users-user-email"
                    >
                      {{ subItem.email }}
                    </v-flex>
                    <v-flex v-if="!!subItem.totalScore">
                      Score: {{ subItem.totalScore }}
                    </v-flex>
                  </v-layout>
                </v-flex>
              </v-layout>
            </v-flex>
          </v-card>
        </draggable>
      </draggable>
    </v-flex>
    <v-dialog v-model="editPlayerDialog" :key="dialogKey" max-width="400px">
      <v-layout justify-center>
        <v-flex xs12>
          <v-toolbar color="indigo" dark height="50px">
            <v-toolbar-title>Edit {{ player.firstname }}</v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card>
            <v-container fluid grid-list-md>
              <v-layout row wrap>
                <v-flex>
                  <form @submit.prevent="onSignin">
                    <v-card-text>
                      <v-text-field
                        v-model="player.firstname"
                        name="firstname"
                        label="First Name"
                      />
                    </v-card-text>
                    <v-card-text>
                      <v-text-field
                        v-model="player.username"
                        name="username"
                        label="UserName"
                      />
                    </v-card-text>
                    <v-card-text v-if="!!user.super">
                      <v-select
                        v-model="accessLevel"
                        :items="accessLevels"
                        label="ACCESS"
                        item-text="text"
                        item-value="code"
                      />
                    </v-card-text>
                  </form>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn @click="editPlayerDialog = false"> Cancel </v-btn>
                    <v-btn class="primary" @click="savePlayer()"> Save </v-btn>
                    <v-btn icon @click="deletePlayer()">
                      <v-icon>delete</v-icon>
                    </v-btn>
                    <v-btn icon @click="bouncePlayer()">
                      <v-icon>call_made</v-icon>
                    </v-btn>
                  </v-card-actions>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
    </v-dialog>
    <v-dialog v-model="editTeam" max-width="400px"
      ><v-layout justify-center>
        <v-flex xs12>
          <v-toolbar
            :color="$theme.get('--primary-accent-color')"
            dark
            height="50px"
          >
            <v-toolbar-title>Create Team </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-card>
            <v-container fluid grid-list-md>
              <v-layout row wrap>
                <v-flex>
                  <form @submit.prevent="onSignin">
                    <v-card-text>
                      <v-text-field
                        v-model="team.name"
                        name="firstname"
                        label="Team Name"
                      />
                    </v-card-text>
                  </form>
                  <v-card-actions>
                    <v-spacer />
                    <v-btn
                      class="pick-team-btn-cancel"
                      @click="editTeam = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn class="pick-team-btn-save" @click="saveTeam">
                      Save
                    </v-btn>
                  </v-card-actions>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
    </v-dialog>
  </v-flex>
</template>

<script>
import draggable from "vuedraggable"
import { mapActions, mapGetters } from "vuex"
import { uniqBy } from "lodash"
import { Role, copyToClipboard } from "@/helpers"
import _ from "lodash"
import { db } from "@/firebase"
import { USER_ACCESS_LEVELS } from "@/config"
import UserService from "@/services/user"
import User from "@shared/User"

export default {
  name: "GameUsers",
  components: {
    draggable
  },
  data() {
    return {
      player: {},
      editPlayerDialog: false,
      editTeam: false,
      teamAction: "Edit",
      team: {},
      dialogKey: null,
      emails: [],
      accessLevels: USER_ACCESS_LEVELS,
      singleSelect: false,
      selected: [],
      headers: [
        {
          text: "Dessert (100g serving)",
          align: "start",
          sortable: false,
          value: "name"
        },
        { text: "Calories", value: "calories" },
        { text: "Fat (g)", value: "fat" },
        { text: "Carbs (g)", value: "carbs" },
        { text: "Protein (g)", value: "protein" },
        { text: "Iron (%)", value: "iron" }
      ],
      desserts: [
        {
          name: "Frozen Yogurt",
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
          iron: "1%"
        },
        {
          name: "Ice cream sandwich",
          calories: 237,
          fat: 9.0,
          carbs: 37,
          protein: 4.3,
          iron: "1%"
        },
        {
          name: "Eclair",
          calories: 262,
          fat: 16.0,
          carbs: 23,
          protein: 6.0,
          iron: "7%"
        },
        {
          name: "Cupcake",
          calories: 305,
          fat: 3.7,
          carbs: 67,
          protein: 4.3,
          iron: "8%"
        },
        {
          name: "Gingerbread",
          calories: 356,
          fat: 16.0,
          carbs: 49,
          protein: 3.9,
          iron: "16%"
        },
        {
          name: "Jelly bean",
          calories: 375,
          fat: 0.0,
          carbs: 94,
          protein: 0.0,
          iron: "0%"
        },
        {
          name: "Lollipop",
          calories: 392,
          fat: 0.2,
          carbs: 98,
          protein: 0,
          iron: "2%"
        },
        {
          name: "Honeycomb",
          calories: 408,
          fat: 3.2,
          carbs: 87,
          protein: 6.5,
          iron: "45%"
        },
        {
          name: "Donut",
          calories: 452,
          fat: 25.0,
          carbs: 51,
          protein: 4.9,
          iron: "22%"
        },
        {
          name: "KitKat",
          calories: 518,
          fat: 26.0,
          carbs: 65,
          protein: 7,
          iron: "6%"
        }
      ]
    }
  },
  computed: {
    ...mapGetters("auth", ["user"]),
    accessLevelUser() {
      return this.player && this.player.id && this.users
        ? this.users[this.player.id]
        : null
    },
    accessLevel: {
      get() {
        if (!this.accessLevelUser) return 3
        console.log("this.accessLevelUser", this.accessLevelUser)
        if (this.accessLevelUser.super) {
          return 0
        } else if (this.accessLevelUser.role === Role.Host) {
          return 1
        } else if (this.accessLevelUser.role === Role.Audit) {
          return 2
        } else if (this.accessLevelUser.role === Role.Spectator) {
          return 3
        } else if (this.accessLevelUser.role === Role.Player) {
          return 4
        } else {
          throw new Error("Wrong access level code")
        }
      },
      async set(value) {
        try {
          if (value === 0) {
            const message = `${this.player.firstname} ${this.player.lastname} will be able to give root access to other users`
            if (!confirm(message)) throw new Error("Aborted")
            // add to super admin
            await db.ref().update({
              [`access/1/${this.player.id}`]: null,
              [`access/0/${this.player.id}`]: true
            })
            await this.$store.dispatch("updateUser", {
              userID: this.player.id,
              obj: { role: Role.Host, super: true }
            })
          } else if (value === 1) {
            // add to regular admin
            await db.ref().update({
              [`access/1/${this.player.id}`]: true,
              [`access/0/${this.player.id}`]: null
            })
            await this.$store.dispatch("updateUser", {
              userID: this.player.id,
              obj: { role: Role.Host, super: null }
            })
          } else if (value === 2 || value === 3 || value === 4) {
            // remove from admin and super admin
            await db.ref().update({
              [`access/1/${this.player.id}`]: null,
              [`access/0/${this.player.id}`]: null
            })
            if (value === 2) {
              await this.$store.dispatch("updateUser", {
                userID: this.player.id,
                obj: { role: Role.Audit, teamID: 0, super: null }
              })
            } else if (value === 3) {
              await this.$store.dispatch("updateUser", {
                userID: this.player.id,
                obj: { role: Role.Spectator, teamID: 0, super: null }
              })
            } else if (value === 4) {
              await this.$store.dispatch("updateUser", {
                userID: this.player.id,
                obj: { role: Role.Player, super: null }
              })
            } else {
              throw new Error("Wrong access level code")
            }
          }
        } catch (e) {
          alert(e.message)
          this.dialogKey = Date.now()
          console.error(e)
        }
      }
    },
    currMission() {
      return this.$store.getters.getCurrentMission
    },
    currMissionID() {
      return this.$store.getters.currentMission
    },
    teams() {
      return this.$store.getters.chats
    },
    onlineUsersIDs() {
      return this.onlineUsers.map(({ id }) => id)
    },
    onlineUsers() {
      return User.normalize(this.users ?? {})
    },
    orgID() {
      return this.$store.getters.orgID
    },
    onlineUsersWithEmails() {
      return this.onlineUsers.map(user => {
        const found = this.emails.find(({ userID }) => userID === user.id)
        return {
          ...user,
          email: found ? found.email : null
        }
      })
    },
    plays() {
      return this.$store.getters.plays
    },
    allTeams() {
      var AllTeams = []
      var ids = Object.keys(this.teams || {})
      var teams = Object.values(this.teams || {})
      for (var i = 0; i < ids.length; i++) {
        AllTeams.push({
          totalScore: teams[i].totalScore,
          color: teams[i].color,
          active: true,
          rank: i + 1,
          action: "local_activity",
          name: teams[i].name,
          id: ids[i],
          players: this.numOfPlayers(ids[i]),
          items: this.playersOnTeam(ids[i])
        })
      }
      this.sortedArray(AllTeams, "totalScore")
      return AllTeams
    }
  },
  watch: {
    onlineUsersIDs: {
      async handler(newValue = [], oldValue = []) {
        const difference = _.difference(newValue, oldValue)
        const users = await this.fetchEmails(difference)
        this.emails = [...this.emails, ...users]
      },
      immediate: true,
      deep: true
    }
  },
  methods: {
    ...mapActions(["updateTeam", "createTeam"]),
    async getTheUser(userID) {
      var snapshot = null
      const ref = db.ref("org/1/users/" + userID)
      return (snapshot = await ref.once("value"))
    },
    copyToClipboard(string) {
      copyToClipboard(string)
      alert(`COPIED TO CLIPBOARD "${string}"`)
    },
    async fetchEmails(userIDs) {
      const promises = userIDs.map(async userID => {
        const snapshot = await db
          .ref(`users/private/${userID}/email`)
          .once("value")
        return { userID, email: snapshot.val() }
      })
      const users = await Promise.all(promises)
      return users
    },
    onCreateTeam() {
      this.editTeam = true
      this.team = {}
      this.teamAction = "create"
    },
    async fetchPlays(gameID) {
      const snapshot = await db
        .auxiliary()
        .ref("org/" + this.orgID + "/game/" + gameID + "/play")
        .once("value")
      return snapshot.val()
    },
    async saveTeam() {
      if (this.team.id) {
        await this.updateTeam(this.team)
      } else {
        const obj = {}
        obj.name = this.team.name
        obj.icon = "brightness_2"
        obj.show = true
        obj.muted = false
        obj.totalScore = 0
        obj.muted_facilitator = false
        obj.players = 0
        obj.active = true
        obj.slogan = "Manual"
        await this.createTeam(obj)
      }
      this.team = {}
      this.editTeam = false
    },
    async selectForSocial(player) {
      this.$store.dispatch("updateUser", {
        userID: player.id,
        obj: { selected: !player.selected }
      })
    },
    async selectTeam(team) {
      for (var i = 0; i < team.items.length; i++) {
        const selected = true
        const userID = team.items[i].id
        this.$store.dispatch("updateUser", { userID, obj: { selected } })
      }
    },
    async savePlayer() {
      this.editPlayerDialog = false
      const player = { ...this.player }
      // those should not be affected by this function
      delete player.role
      delete player.super
      this.$store.dispatch("updateUser", {
        userID: player.id,
        obj: { ...player, correct: null, theScore: null }
      })
    },
    async deletePlayer() {
      await this.$store.dispatch("deletePlayer", { userID: this.player.id })
      this.editPlayerDialog = false
    },
    async bouncePlayer() {
      this.editPlayerDialog = false
      if (this.player) UserService.updateTeamId(this.player, 0)
    },
    editPlayer(p) {
      this.player = p
      this.editPlayerDialog = true
    },
    changeTeam(e, teamID) {
      const user = e.added?.element
      if (user) UserService.updateTeamId(user, teamID)
    },
    onlyUnique(value, index, self) {
      return self.indexOf(value) === index
    },
    getTotalScore(u) {
      if (this.plays) {
        var plays = this.plays
        var newPlays
        if (plays) {
          newPlays = plays.filter(item => {
            return item.userID == u
          })
        }
        var score = 0
        for (var i in newPlays) {
          score = score + newPlays[i].score
        }
        return parseInt(score)
      }
    },
    sortedArray(arr, field) {
      return arr.sort((a, b) => parseInt(b[field]) - parseInt(a[field]))
    },
    playersOnTeam(teamID) {
      var players = []
      console.log("JUST PLAYS", this.plays.length)
      var teamPlays = this.plays.filter(item => item.teamID == teamID)
      console.log("TEAMID PLAYS", teamPlays.length)
      for (var i in teamPlays) {
        if (teamPlays[i].firstName)
          players.push({
            firstname: teamPlays[i].firstName,
            lastname: teamPlays[i].lastName,
            totalScore: this.getTotalScore(teamPlays[i].userID),
            teamID: teamPlays[i].teamID
          })
      }
      var uniqPlayers = uniqBy(players, "firstname")
      // players = players.filter(this.onlyUnique)
      // console.log("PLAYERS", uniqPlayers)
      return uniqPlayers
    },
    playersOffTeam() {
      if (this.onlineUsersWithEmails) {
        var uArr = this.onlineUsersWithEmails
        uArr = uArr.filter(item => {
          return item.teamID == 0
        })
        return uArr
      }
    },
    isString(value) {
      return typeof value === "string" || value instanceof String
    },
    isObject(value) {
      return value && typeof value === "object" && value.constructor === Object
    },
    getPlay(userID) {
      if (this.plays) {
        var arr = this.plays
        for (var i = 0; i < arr.length; i++) {
          if (
            arr[i].userID == userID &&
            arr[i].missionID == this.currMissionID
          ) {
            return arr[i].correct
          }
        }
      }
    },
    numOfPlayers(chat_id) {
      if (this.onlineUsersWithEmails) {
        var uArr = this.onlineUsersWithEmails
        var p = 0
        for (var i = 0; i < uArr.length; i++) {
          if (uArr[i].teamID === chat_id) {
            p++
          }
        }
        return p
      }
    }
  }
}
</script>
<style lang="scss">
.rtb-game-users-page {
  .game-users-user-email {
    font-weight: normal;
    cursor: pointer;
    text-decoration: underline;
    max-width: 200px !important;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .team-delete {
    clear: both;
    margin-top: 63px !important;
    float: right;
    margin-right: -9px !important;
  }
  .team-edit {
    margin-top: 62px !important;
    float: right;
    margin-right: -10px !important;
  }
  .player-card {
    cursor: move;
  }
  .team-name {
    height: 20px;
    min-width: 500px;
    padding: 2px 4px 0 10px;
    font-weight: bold;
    margin-top: 2px;
    clear: both;
    min-height: 120px;
    width: 100%;
    border: 1px dashed $color-grey-light1;
    background-color: $color-grey-light;
  }
  .move-player {
    padding: 4px;
  }
  .correct-play {
    background-color: $color-mint !important;
    padding: 4px;
  }
  .v-list__group__items--no-action .v-list__tile {
    padding-left: 25px !important;
  }
  #team-list {
    font-size: 15px;
  }
  .v-list__tile__title {
    font-size: 13px;
  }
  .team-avatar {
    width: 40px;
  }
  .v-list__group__header {
    height: 32px;
  }
  #teams-list {
    padding: 0;
  }
  .team-score {
    font-size: 11px;
    font-weight: bold;
  }
  .user-score {
    font-size: 11px;
    font-weight: bold;
  }
  .v-list__group__header__append-icon {
    display: none;
  }
  .dragArea {
    min-height: 15px;
  }
}
</style>
